/*
 * CB Tables
 */

@import "apricot-variable.scss";
@import "../mixins/table.scss";
@import "../mixins/breakpoint.scss";

// ------------------------------------ BASE

.cb-table {
  @include table();

  // Table Utility classes
  &.cb-no-border {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-right: 0;

          &:first-child {
            border-left: 0;
          }
        }

        &:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  &.cb-table-condensed {
    th,
    td {
      padding: $table-condensed-cell-padding;
      font-size: $font-size-small;
      line-height: $line-height-small;
    }
  }

  &.cb-table-striped {
    > tbody > tr {
      &:nth-of-type(even) {
        background-color: $white;
      }

      &:nth-of-type(odd) {
        background-color: $gray5;
      }
    }
  }

  &.cb-table-striped-reverse {
    > tbody > tr {
      &:nth-of-type(odd) {
        background-color: $white;
      }

      &:nth-of-type(even) {
        background-color: $gray5;
      }
    }
  }

  // ------------------------------------ CELL
  .cb-table-sort {
    vertical-align: middle;
    padding: 5px 10px;
    border-left-width: 0;
    border-right-width: 0;
    background: $white;

    a {
      text-decoration: none !important;
      display: block;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none !important;
      }

      .cb-sort-desc,
      .cb-sort-asc {
        color: $blue2;
      }

      .cb-sort {
        color: $black1;
      }
    }

    &:hover {
      background-color: $blue6;
      cursor: pointer;
    }

    &:first-of-type {
      border-left-width: 1px;
    }

    &:last-of-type {
      border-right-width: 1px;
    }
  }

  .cb-table-sort-joined {
    background: $white;
    color: $black1;
    border-top-width: 2px;
    border-bottom-width: 2px;
    a {
      text-decoration: none !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: $black1;
        span {
          &:nth-of-type(1) {
            text-decoration: underline;
          }
          &.cb-glyph {
            text-decoration: none !important;
          }
        }
      }

      .cb-sort-desc,
      .cb-sort-asc {
        color: $blue2;
      }

      .cb-sort {
        color: $black1;
      }
    }

    &:hover {
      background-color: $blue6;
      cursor: pointer;
    }
  }

  .cb-table-thumbnail {
    width: 72px;
    height: 72px;
    // .border-box();
    box-sizing: border-box;
    border: 1px solid $table-border-color;
    background-color: $gray3;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      z-index: 2;
      position: absolute;
    }

    &::before {
      content: "?";
      color: $table-border-color;
      font-size: 1.5rem;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & + p {
      display: inline-block;
      float: left;
    }
  }

  .cb-table-tip {
    &.cb-glyph {
      font-size: 0.75rem;
      line-height: 1em;
      display: inline-block;
      float: right;
    }
  }

  .cb-table-callout {
    background-color: $gray5;
  }

  > thead {
    .cb-table-callout,
    &.cb-table-callout {
      color: $black1 !important;
    }
  }

  .cb-table-highlight {
    background-color: $yellow2!important;
  }
  .cb-table-selected {
    background-color: $blue6!important;
  }
}

.cb-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  // Prevent double border on horizontal scroll due to use of `display: block;`
  > .cb-table {
    border: 0;
  }
}

.cb-table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .cb-table {
          border: 0;
        }
      }
    }
  }
}

.cb-table-mobile {
  td {
    width: 50%;
  }
  .cb-last-row {
    td,
    th {
      border-bottom-color: $gray2;
      padding-bottom: 24px;
    }
    &:last-of-type {
      td,
      th {
        border-bottom-color: $gray2;
      }
    }
    & + tr {
      td,
      th {
        padding-top: 24px;
      }
    }
  }
}
