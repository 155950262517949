/*
 * CB Image mixins
 */

// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-fluid {
  // Part 1: Set a maximum relative to the parent
  max-width: 100%;
  // Part 2: Override the height to auto, otherwise images will be stretched
  // when setting a width and height attribute on the img element.
  height: auto;
}

@mixin img-size($width, $height: $width) {
  width: #{$width}px;
  height: #{$height}px;
}

// SVG Logos
@mixin background-logo($name) {
  $cb-image-path-aws: "//mango.collegeboard.org/cbmango1/prod/ui/all/1/";
  background-image: url(#{$cb-image-path-aws}#{$name}.svg);
  background-repeat: no-repeat;
  background-position: center center;
}