/*
 * CB Utils
 */

 .cb-no-outline {
	 outline: 0;
 }

.cb-tmp-element{
  position: absolute;
  left: -9999px;
}

.sr-only,
.cb-sr-only{
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.cb-no-text-select {
	user-select: none;
 }



// ------------------------------------ DEVELOPMENT

// Add to body tag
body {
	&.cb-dev-env {
		&.cb-show-breakpoints {
			&::before {
				color: $white;
				background: $gray1;
				display: inline-block;
				padding: 1em;
				opacity: .6;
				position: fixed;
				left: 50%;
				transform: translateX(-50%);
				z-index: 99999;
			}
			@each $breakpoint, $value in $grid-breakpoints {
				@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
					&::before {
						content: quote(#{$breakpoint}-#{$value});
					}
				}
			}
		}
	}
}
