/*
 * CB Borders
 */

// ------------------------------------ BORDER: COLOR, DEFAULT

.cb-border-color {
  border-color: $gray4
}

.cb-border-width {
  border-width: 1px !important;
}

.cb-border-style {
  border-width: 0;
  border-style: solid;
}



// ------------------------------------ BORDER: 1px 

.cb-border {
  border: $border-width solid $border-color;
}

.cb-no-border {
  border: 0 !important;
}

.cb-no-border-radius {
  border-radius: 0!important;
}

.cb-no-border-radius-tl {
  border-top-left-radius: 0!important;
}
.cb-no-border-radius-tr {
  border-top-right-radius: 0!important;
}

.cb-no-border-radius-bl {
  border-bottom-left-radius: 0!important;
}
.cb-no-border-radius-br {
  border-bottom-right-radius: 0!important;
}  

@each $type in $borderType {
  .cb-border-#{$type} {
    border-#{$type}: $border-width solid $border-color;
  }

  .cb-no-border-#{$type} {
    border-#{$type}: 0 !important;
  }
}


// ------------------------------------ BORDER STYLE


@each $style in $borderStyle {
  .cb-border-#{$style} {
    border-width: 0;
    border-style: $style;
  }
}

// ------------------------------------ BORDER WIDTH and POSITION

@each $width in $borderWidth {
  .cb-border-#{$width} {
    border-width: #{$width}px !important;
  }

  @each $type in $borderType {
    .cb-border-#{$type}-#{$width} {
      border-#{$type}-width: #{$width}px !important;
    }
  }
}

// ------------------------------------ RESPONSIVE

@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .cb-border-#{$breakpoint} {
      border: $border-width solid $border-color;
    }

    .cb-no-border-#{$breakpoint} {
      border: 0 !important;
    }

    @each $type in $borderType {
      .cb-border-#{$breakpoint}-#{$type} {
        border-#{$type}: $border-width solid $border-color;
      }

      .cb-no-border-#{$breakpoint}-#{$type} {
        border-#{$type}: 0 !important;
      }
    }
  }
}

@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .cb-border-#{$breakpoint}-up {
      border: $border-width solid $border-color;
    }

    .cb-no-border-#{$breakpoint}-up {
      border: 0 !important;
    }

    @each $type in $borderType {
      .cb-border-#{$breakpoint}-up-#{$type} {
        border-#{$type}: $border-width solid $border-color;
      }

      .cb-no-border-#{$breakpoint}-up-#{$type} {
        border-#{$type}: 0 !important;
      }
    }
  }
}


@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    .cb-border-#{$breakpoint}-down {
      border: $border-width solid $border-color;
    }

    .cb-no-border-#{$breakpoint}-down {
      border: 0 !important;
    }

    @each $type in $borderType {
      .cb-border-#{$breakpoint}-down-#{$type} {
        border-#{$type}: $border-width solid $border-color;
      }

      .cb-no-border-#{$breakpoint}-down-#{$type} {
        border-#{$type}: 0 !important;
      }
    }
  }
}


// ------------------------------------ BORDER RADIUS
.cb-border-radius {
  border-radius: $border-radius;
}
.cb-border-radius-8 {
  border-radius: $border-radius;
}
.cb-border-radius-16 {
  border-radius: 16px;
}

@each $size in $radiusSize {
  .cb-border-radius-top-left-#{$size} {
    border-top-left-radius: #{$size}px!important;
  }
  .cb-border-radius-top-right-#{$size} {
    border-top-right-radius: #{$size}px!important;
  }
  .cb-border-radius-bottom-left-#{$size} {
    border-bottom-left-radius: #{$size}px!important;
  }
  .cb-border-radius-bottom-right-#{$size} {
    border-bottom-right-radius: #{$size}px!important;
  }
}

// ------------------------------------ BOX SHADOW

.cb-box-shadow {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
}

.cb-box-shadow-right {
  box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.1);
}

.cb-box-shadow-left {
  box-shadow: -4px 0px 4px -2px rgba(0, 0, 0, 0.1);
}

.cb-box-shadow-bottom {
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
}

.cb-box-shadow-top {
  box-shadow: 0px -4px 4px -2px rgba(0,0,0,0.1);
}


// ------------------------------------ light
.cb-box-shadow-light {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

.cb-box-shadow-top-light {
  box-shadow:  0 -5px 11px -5px rgba(0, 0, 0, 0.1);
}

.cb-box-shadow-left-light {
  box-shadow: -5px 0 11px -5px rgba(0, 0, 0, 0.1);

}

.cb-box-shadow-right-light {
  box-shadow: 5px 0 11px -5px rgba(0, 0, 0, 0.1);
}

.cb-box-shadow-bottom-light {
  box-shadow: 0 5px 11px -5px rgba(0, 0, 0, 0.1);
}

// ------------------------------------ reset
.cb-no-box-shadow {
  box-shadow: none!important;
}