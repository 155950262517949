/*
 * CB Spacer mixins
 */


@mixin spacer($type, $map) {
  $class-name: cb-spacer#{$type};

  @each $key,
  $value in $map {

    @if ($type=="v") {
      .#{$class-name}-#{$key},
      spacer#{$type}#{$key} {
        display: block;
        height: #{$value}px;
      }

      .#{$class-name}-top-#{$key} {
        margin-top: #{$value}px;
      }

      .#{$class-name}-bottom-#{$key}{
        margin-bottom: #{$value}px;
      }
    }

    @else if ($type=="h") {

      .#{$class-name}-#{$key},
      spacer#{$type}#{$key} {
        display: inline-block;
        width: #{$value}px;
      }

      .#{$class-name}-left-#{$key} {
        margin-left: #{$value}px;
      }

      .#{$class-name}-right-#{$key} {
        margin-right: #{$value}px;
      }
    }
  }
}

@mixin custom-spacer($type, $space) {
  .cb-spacer#{$type}-custom {
    @if ($type=="h") {
      display: inline-block;
      min-width: calc(var(--cb-spacer-num) * #{$space}px);
      max-width: calc(var(--cb-spacer-num) * #{$space}px);
    }

    @else if ($type=="v") {
      display: block;
      min-height: calc(var(--cb-spacer-num) * #{$space}px);
      max-height: calc(var(--cb-spacer-num) * #{$space}px);
    }
  }
}