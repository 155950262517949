/*
 * CB Images
 */


// Responsive images (ensure images don't scale beyond their parents)
.cb-img-fluid {
  @include img-fluid;
}

.cb-img-stretch {
  min-width: 100%; 
  width: 100%;
}

// Image thumbnails
.cb-img-thumbnail {
  background-color: $gray3;
  border: 1px solid $border-color;

  // Keep them at most 100% wide
  @include img-fluid;
}

.cb-img-circular {
  border-radius: 50%;
}

.cb-img-grayscale {
  filter: grayscale(100%);
}

.cb-fade-in {
  &.cb-active-effect {
    animation-name: fadeIn;
    animation-duration: 3s;
  }
}

.cb-fade-out {
  &.cb-active-effect {
  animation-name: fadeOut;
  animation-duration: 3s;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}