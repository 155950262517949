/*
 * CB Glyph mixins
 */

@function icon($character) {
  @return unquote('\"')+unquote(str-insert($character, '\\', 1))+unquote('\"');
}

@mixin glyph-font($name) {
  font-family: $name;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  @include font-smoothing();
}

@mixin glyph-color ($color) {
  color: $color;

  &.cb-glyph-numeric {
    border-color: $color;

    &.cb-glyph-circular {
      color: $white-color;
      border-color: $color;
      background-color: $color;
    }
  }
}

@mixin glyph-multi-content-color ($color1, $color2, $bk: null) {
  @if ($bk !=null) {
    background-color: $bk;
  }

  &::before {
    color: $color1;
  }

  &::after {
    color: $color2;
  }
}

@mixin glyph-content($before: null, $after: null, $imp: null) {
  &::before {
    content: icon($before);
  }

  @if ($after !=null) {

    &::after {
      content: icon($after);
    }
  }
}

@mixin border-box() {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@mixin print-glyph-numeric() {
  // Glyphs 
  .cb-glyph-numeric {
    border-color: $black !important;
    color: $black !important;

    &.cb-glyph-circular {
      @each $color,
      $map in $colorThemes {
        &[class*="cb-#{$color}"] {
          color: $black !important;

        }
      }

      color: $black !important;
      &::before {
        color: $black !important;
      }
    }
  }
}