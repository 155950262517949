/*
 * CB Dialog
 */
@import "apricot-variable.scss";

.cb-dialog {
  border: 1px solid $border-color;

  .cb-dialog-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;

    .cb-dialog-header {
      display: flex;
      align-items: center;
      min-height: 30px;

      >.cb-glyph {
        margin-right: 16px;
        flex-shrink: 0;
      }

      .cb-btn-close {
        margin-left: auto;
      }
    }

    .cb-dialog-title {
      font-size: 1.3125rem; //21
      line-height: 1.1428571428571428em; //24
      font-weight: 400;
    }
    .cb-dialog-content {
      display: flex;
      flex-direction: column;
      padding-right: 24px;
      padding-left: 48px;
      .cb-btn-row {
        margin-top: 24px;
      }
    }
    .cb-dialog-header {
      +.cb-dialog-content {
        margin-top: 12px;
      }
    }
  }

  height: auto;
  transition: height $transition-time-l ease;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &.cb-dialog-hide {
    border: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
  }
}