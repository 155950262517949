/*
 * CB Popover
 */
 
 @import "apricot-variable.scss";
 @import "../mixins/breakpoint.scss";
 @import "../mixins/font.scss";

.cb-popover {
  position: absolute;

  background-color: $white;
  color: $black1;

  min-width: $tooltip-min-width;
  max-width: $tooltip-max-width;  
  border: 1px solid $tooltip-border-color;
  border-radius: $tooltip-border-radius;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  z-index: $zindex-tooltip;
  opacity: 0;
  transition: opacity $transition-time-l ease;
  text-align: left;

  /* Mobile */
  @include media-breakpoint-down('xs', $grid-breakpoints) {
    max-width: calc(100vw - 48px);
  }
  
  &[aria-hidden="false"] {
    display: block;
  }
  &[aria-hidden="true"] {
    display: none;
  }

  &[x-placement^="top"] {
    opacity: 1;
    margin-bottom: $tooltip-spacer;
  }

  &[x-placement^="bottom"] {
    opacity: 1;
    margin-top: $tooltip-spacer;
  }

  &[x-placement^="right"]{
    opacity: 1;
    margin-left: $tooltip-spacer;
  }

  &[x-placement^="left"] {
    opacity: 1;
    margin-right: $tooltip-spacer;
  }

  .cb-popover-inner {
    padding: 24px;
    .cb-popover-header {
      margin-bottom: 24px;
      .cb-popover-title{
        font-weight: 400;
        
        font-size: 1.4375rem;
        line-height: 1.0434782608695652em;
      }
    }
  }
}
