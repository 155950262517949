/*
 * CB table mixins
 */

@mixin table() {
  width: 100%;
  color: $black1;
  background-color: transparent; // Reset for nesting within parents with `background-color`.

  font-size: 1rem;
  line-height: 1.5em;
  >thead {
    background: $gray1;
    color: $white;

    >tr {
      >th {
        font-weight: 700;
      }
    }
  }

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border: $table-border-width solid $table-border-color;
    min-width: 80px;
  }
}