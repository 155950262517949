/*
* CB Utility mixins
*/
@mixin namespace($parent: false, $namespace: $apricotNamespace) {
  @if $namespace {
    @if $parent {
      &.#{$namespace} {
        @content;
      }
    }  @else {
      .#{$namespace} {
        @content;
      }
    }
  } @else {
    @content;
  }
}

@mixin border-box(){
  box-sizing: border-box;
} 

@mixin cb-no-focus() {
  outline-width: 0!important;
  outline: none!important;
}
@mixin no() {
  display: none!important;
}