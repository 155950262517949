/*
 * CB Link mixins
 */

@mixin default-link() {
  // blue
  color: $link-color;
  text-decoration: underline;

  &:hover,
  &.cb-hover {
    color: $link-hover-color;
    text-decoration: none;
  }

  &:active,
  &.cb-active {
    color: $link-active-color;
  }

  &:not(.cb-btn):visited,
  &:not(.cb-btn).cb-visited {
    color: $link-visited-color;
  }
  &.cb-disabled {
    @include disabled-link();
  }
}

@mixin black-link() {
  // black
  color: $link-color-black;

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus {
    color: $link-color-black;
  }

  &:active,
  &.cb-active {
    color: $link-active-color-black;
  }

  &:visited,
  &.cb-visited {
    color: $link-color-black;
  }
  &.cb-disabled {
    @include disabled-link();
  }
}

@mixin white-link() {
  // white
  color: $white !important;

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus {
    color: $white;
  }

  &:active,
  &.cb-active {
    color: $white;
  }

  &:visited,
  &.cb-visited {
    color: $white;
  }
  &.cb-disabled {
    @include disabled-link();
  }
}

@mixin disabled-link() {
  color: $gray3!important;
  text-decoration: none!important;
  cursor: not-allowed;
  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus,
  &.cb-active,
  &:visited,
  &.cb-visited {
    color: $white;
    color: $gray3!important;
    text-decoration: none!important;
  }
}

// ------------------  Action
@mixin cb-action-tag() {
  font-size: $font-size-small; //14
  line-height: $line-height-small; //16
  font-weight: 400;
  color: $blue5;
  text-transform: uppercase;
  a {
    color: $blue5;
    text-decoration: none;
    // text-transform: uppercase;
    &:hover,
    &.cb-hover,
    &:focus,
    &.cb-focus {
      color: $blue5;
      text-decoration: underline;
    }

    &:active,
    &.cb-active {
      color: $blue5;
    }

    &:visited,
    &.cb-visited {
      color: $blue5;
    }
  }
}

// ------------------  Palette
@mixin palette-link($color, $activeColor) {
  // black
  color: $color;

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus {
    color: $color;
  }

  &:active,
  &.cb-active {
    color: $activeColor;
  }

  &:visited,
  &.cb-visited {
    color: $color;
  }
  &.cb-disabled {
    @include disabled-link();
  }
}