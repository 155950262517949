/*
 * CB Loader
 */

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";

// --------------------------------------- For React
.cb-css-transition-enter {
  max-height: 0;
  opacity: 0;
}

.cb-css-transition-enter-active {
  opacity: 1;
  transition: all 500ms;
}

.cb-css-transition-exit {
  opacity: 1;
}

.cb-css-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.cb-css-transition-display-enter {
  opacity: 0;
}

.cb-css-transition-display-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.cb-css-transition-display-exit {
  opacity: 1;
}

.cb-css-transition-display-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

// CB.ORG OPT11

$shimmer-gradient-1: #eff1f3;
$shimmer-gradient-dark: #fffefe;
$shimmer-gradient-light: #e2e2e2;

$lineValue: 4,
8,
16,
24,
32,
48,
72;

// General
@each $value in $lineValue {
  &.cb-line-#{$value} {
    height: #{$value}px;
  }
}

// Per breakpoint
@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $value in $lineValue {
      &.cb-line-#{$breakpoint}-#{$value} {
        height: #{$value}px;
      }
    }
  }

  // Range
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $value in $lineValue {
      &.cb-line-#{$breakpoint}-up-#{$value} {
        height: #{$value}px;
      }
    }
  }

  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $value in $lineValue {
      &.cb-line-#{$breakpoint}-down-#{$value} {
        height: #{$value}px;
      }
    }
  }
}

.cb-loader-shimmer {
  &:not(.cb-loader-light) {
    div[class*="cb-line"] {
      background: linear-gradient(
        /* -45deg,*/
        to right,
        $shimmer-gradient-1 4%,
        $shimmer-gradient-light 25%,
        $shimmer-gradient-1 36%
      );

      animation: shimmer 4s infinite linear;
      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
      background-size: 1000px 100%;
      border-radius: 10px;
    }
  }

  &.cb-loader-light {
    div[class*="cb-line"] {
      background: linear-gradient(
        to right,
        $shimmer-gradient-1 4%,
        $shimmer-gradient-dark 25%,
        $shimmer-gradient-1 36%
      );
      animation: shimmer 4s infinite linear;
      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
      background-size: 1000px 100%;
      border-radius: 10px;
    }
  }

  // Banner
  &.cb-loader-band,
  &.cb-loader-hero-band {
    [class*="cb-line"] {
      &:nth-of-type(1),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        width: 300px;
      }
      &:nth-of-type(2) {
        width: 400px;
      }
    }

    @include media-breakpoint-only('sm', $grid-breakpoints) {
      [class*="cb-line"] {
        &:nth-of-type(1) {
          width: 200px;
        }
        &:nth-of-type(2) {
          width: 80%;
        }
      }
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      [class*="cb-line"] {
        &:nth-of-type(1) {
          width: 100px;
        }
        &:nth-of-type(2) {
          width: 200px;
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
          width: 100%;
        }
      }
    }
  }

  // Section
  &.cb-loader-section {
    [class*="cb-line"] {
      &:first-of-type {
        width: 200px;
      }
    }
  }

  &.cb-loader-hero-band {
    .justify-content-center {
      align-items: center;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}