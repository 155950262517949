/*
 * CB Grid
 */

@import "apricot-variable.scss";

@import "../mixins/breakpoint.scss";
@import "../mixins/grid-framework";
@import "../mixins/grid";

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  .container {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
  .container-fluid {
    @include make-container();
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}

//
// Utilities for common `display` values
//
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $displays {
      .display#{$infix}-#{$value} {
        display: $value !important;
      }
    }
    @each $value in $positions {
      .position#{$infix}-#{$value} {
        position: $value !important;
      }
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $displays {
      .display#{$infix}-#{$value}-only {
        display: $value !important;
      }
    }

    @each $value in $positions {
      .position#{$infix}-#{$value}-only {
        position: $value !important;
      }
    }
  }
}

@each $value in $displays {
  .display-#{$value} {
    display: $value !important;
  }
}

@each $value in $positions {
  .position-#{$value} {
    position: $value !important;
  }
}

//
// Utilities for common `hidden` values
//
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .hidden-#{$breakpoint}-only,
    .hidden-#{$breakpoint},
    .cb-hidden-#{$breakpoint} {
      display: none !important;
    }
  }

  // Range
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .hidden-#{$breakpoint}-up,
    .cb-hidden-#{$breakpoint}-up {
      display: none !important;
    }
  }
}
.cb-hidden,
.hidden {
  display: none !important;
}

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row {
      flex-direction: row !important;
    }

    .flex#{$infix}-column {
      flex-direction: column !important;
    }

    .flex#{$infix}-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex#{$infix}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex#{$infix}-wrap {
      flex-wrap: wrap !important;
    }

    .flex#{$infix}-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex#{$infix}-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .flex#{$infix}-fill {
      flex: 1 1 auto !important;
    }

    .flex#{$infix}-grow-0 {
      flex-grow: 0 !important;
    }

    .flex#{$infix}-grow-1 {
      flex-grow: 1 !important;
    }

    .flex#{$infix}-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex#{$infix}-shrink-1 {
      flex-shrink: 1 !important;
    }

    .justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }

    .justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }

    .justify-content#{$infix}-center {
      justify-content: center !important;
    }

    .justify-content#{$infix}-between {
      justify-content: space-between !important;
    }

    .justify-content#{$infix}-around {
      justify-content: space-around !important;
    }

    .align-items#{$infix}-start {
      align-items: flex-start !important;
    }

    .align-items#{$infix}-end {
      align-items: flex-end !important;
    }

    .align-items#{$infix}-center {
      align-items: center !important;
    }

    .align-items#{$infix}-baseline {
      align-items: baseline !important;
    }

    .align-items#{$infix}-stretch {
      align-items: stretch !important;
    }

    .align-content#{$infix}-start {
      align-content: flex-start !important;
    }

    .align-content#{$infix}-end {
      align-content: flex-end !important;
    }

    .align-content#{$infix}-center {
      align-content: center !important;
    }

    .align-content#{$infix}-between {
      align-content: space-between !important;
    }

    .align-content#{$infix}-around {
      align-content: space-around !important;
    }

    .align-content#{$infix}-stretch {
      align-content: stretch !important;
    }

    .align-self#{$infix}-auto {
      align-self: auto !important;
    }

    .align-self#{$infix}-start {
      align-self: flex-start !important;
    }

    .align-self#{$infix}-end {
      align-self: flex-end !important;
    }

    .align-self#{$infix}-center {
      align-self: center !important;
    }

    .align-self#{$infix}-baseline {
      align-self: baseline !important;
    }

    .align-self#{$infix}-stretch {
      align-self: stretch !important;
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row-only {
      flex-direction: row !important;
    }

    .flex#{$infix}-column-only {
      flex-direction: column !important;
    }

    .flex#{$infix}-row-reverse-only {
      flex-direction: row-reverse !important;
    }

    .flex#{$infix}-column-reverse-only {
      flex-direction: column-reverse !important;
    }

    .flex#{$infix}-wrap-only {
      flex-wrap: wrap !important;
    }

    .flex#{$infix}-nowrap-only {
      flex-wrap: nowrap !important;
    }

    .flex#{$infix}-wrap-reverse-only {
      flex-wrap: wrap-reverse !important;
    }

    .flex#{$infix}-fill-only {
      flex: 1 1 auto !important;
    }

    .flex#{$infix}-grow-0-only {
      flex-grow: 0 !important;
    }

    .flex#{$infix}-grow-1-only {
      flex-grow: 1 !important;
    }

    .flex#{$infix}-shrink-0-only {
      flex-shrink: 0 !important;
    }

    .flex#{$infix}-shrink-1-only {
      flex-shrink: 1 !important;
    }

    .justify-content#{$infix}-start-only {
      justify-content: flex-start !important;
    }

    .justify-content#{$infix}-end-only {
      justify-content: flex-end !important;
    }

    .justify-content#{$infix}-center-only {
      justify-content: center !important;
    }

    .justify-content#{$infix}-between-only {
      justify-content: space-between !important;
    }

    .justify-content#{$infix}-around-only {
      justify-content: space-around !important;
    }

    .align-items#{$infix}-start-only {
      align-items: flex-start !important;
    }

    .align-items#{$infix}-end-only {
      align-items: flex-end !important;
    }

    .align-items#{$infix}-center-only {
      align-items: center !important;
    }

    .align-items#{$infix}-baseline-only {
      align-items: baseline !important;
    }

    .align-items#{$infix}-stretch-only {
      align-items: stretch !important;
    }

    .align-content#{$infix}-start-only {
      align-content: flex-start !important;
    }

    .align-content#{$infix}-end-only {
      align-content: flex-end !important;
    }

    .align-content#{$infix}-center-only {
      align-content: center !important;
    }

    .align-content#{$infix}-between-only {
      align-content: space-between !important;
    }

    .align-content#{$infix}-around-only {
      align-content: space-around !important;
    }

    .align-content#{$infix}-stretch-only {
      align-content: stretch !important;
    }

    .align-self#{$infix}-auto-only {
      align-self: auto !important;
    }

    .align-self#{$infix}-start-only {
      align-self: flex-start !important;
    }

    .align-self#{$infix}-end-only {
      align-self: flex-end !important;
    }

    .align-self#{$infix}-center-only {
      align-self: center !important;
    }

    .align-self#{$infix}-baseline-only {
      align-self: baseline !important;
    }

    .align-self#{$infix}-stretch-only {
      align-self: stretch !important;
    }
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

// order
.order-first {
  order: -1;
}

.order-last {
  order: $grid-columns + 1;
}

@for $i from 0 through $grid-columns {
  .order-#{$i} {
    order: $i;
  }
}

// Gutter
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.cb-max-width-content {
  max-width: fit-content;
  max-width: -moz-fit-content;
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    // ------------------------------------ ALIGNMENT
    .cb-no-gutters-#{$breakpoint} {
      margin-right: 0;
      margin-left: 0;

      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.cb-flex-row-break {
  display: flex;
  flex-wrap: wrap;
}

.cb-flex-column-break {
  flex-basis: 100%;
  width: 0;
}

// ------------------------------------ VERTICAL GUTTER

.cb-gutterv-24 {
  flex-flow: wrap;
  margin-top: -24px;
  > div, li {
    margin-top: 24px;
  }
}

.cb-gutterv-48 {
  flex-flow: wrap;
  margin-top: -48px;
  > div, li {
    margin-top: 48px;
  }
}

// ------------------------------------ HORIZONTAL GUTTER
.cb-gutterh-24 {
  flex-flow: wrap;
  margin-left: -24px;
  > div, li {
    margin-left: 24px;
  }
}

.cb-gutterh-48 {
  flex-flow: wrap;
  margin-left: -48px;
  > div, li {
    margin-left: 48px;
  }
}

// ------------------------------------ FLOAT

.cb-float-left {
  float: left;
}
.cb-float-right {
  float: right;
}
.cb-float-none {
  float: none;
}
.cb-clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}
