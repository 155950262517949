/*
 * CB Links
 */
 @import "../mixins/link.scss";

a ,
.cb-link-blue,
.cb-link-default {
  @include default-link();
}

a {
  // black
  &.cb-link-black {
    @include black-link();
  }
  // white
  &.cb-link-white {
    @include white-link();
  }
}

.cb-link-black{
  @include black-link();
}


.cb-no-text-decoration {
  text-decoration: none;
}

a:not(.cb-btn) {
  @each $key, $value in $paletteColors {
    &.cb-palette-#{$key} {
      @include palette-link($value, $value);
    }
  }
}