/*
 * CB Colors
 */

@each $color, $map in $colorThemes {
  @each $type, $prop in $colorTypes {
    $valid: map-get($map, $type);
    @if ($valid == 1) {
      .cb-#{$color}-#{$type} {
        #{$prop}: map-get($map, code)!important;
      }
    }
  }
}

// ------------------------------------ GRADIENT EFFECTS

.cb-gray-gradient,
.cb-gray-gradient-bottom {
	background: $gray4;
  background: linear-gradient(0deg, $gray4 0, $gray5 20%, $gray5 100%);
}

@include gradient-top(white, $white, $gray5);
@include gradient-top(gray5, $gray5, $gray4);
@include gradient-top(gray1, $gray1, #404040);
@include gradient-top(blue1, $blue1, #015685);
@include gradient-top(blue2, $blue2, #0069b1);
@include gradient-top(blue3, $blue3, #0085c8);
@include gradient-top(purple1, $purple1, #632a79);
@include gradient-top(green1, $green1, #348339);


// ------------------------------------ COLOR PER BREAKPOINT

// Per breakpoint
@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {

    .cb-white-bg-#{$breakpoint}  {
      background-color: $white!important;
    }
  }
}

