/*
 * CB Stepper
 */

@import "apricot-variable.scss";
@import "../mixins/glyph.scss";
@import "../mixins/stepper.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/font.scss";

.cb-stepper {

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;

    li {
      padding: 0;
      margin: 0;
      position: relative;
      width: 100%;
      text-align: center;
      padding: 0 8px;

      p {
        @include roboto-bold();
        margin: 0;

        &+p {
          font-size: $font-size-small;
          line-height: $line-height-small;
          @include roboto();
        }
      }

      a {
        color: $font-color-base;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: $font-color-base;
        }
      }

      .cb-glyph {
        display: inline-flex;
        color: $white;
      }

      .cb-glyph-circular {
        &::after {
          background-color: transparent;
          border: 2px solid $gray2;
        }

        &::before {
          color: $gray2;
        }
      }

      .cb-glyph-numeric {
        background-color: transparent;
        color: $gray2;
        border-color: $gray2;
        margin-bottom: 8px;
      }

      &::after {
        height: 2px;
        width: calc(100% - 44px);
        background: $gray2;
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        left: calc(50% + 22px);
      }

      // STEP STATES
      &.cb-stepper-active {

        .cb-glyph,
        .cb-glyph-numeric {
          color: $white;
        }

        .cb-glyph-circular {
          &::after {
            background-color: $blue2;
            border: 2px solid $blue2;
          }

          &::before {
            color: $white;
          }
        }

        .cb-glyph-numeric {
          background-color: $blue2;
          border-color: $blue2;
        }

        &::after {
          background: $gray2;
        }
      }

      &.cb-stepper-skip {
        .cb-glyph {
          color: $white;

          &.cb-glyph-circular {
            &::after {
              background-color: $gray2;
              border: 2px solid $gray2;
            }

            &::before {
              color: $white;
            }
          }
        }

        .cb-glyph-numeric {
          background-color: $gray2;
          color: $white;
          border-color: $gray2;
        }

        &::after {
          background: $blue2;
        }
      }

      &.cb-stepper-complete {
        .cb-glyph {
          &.cb-glyph-circular {
            &::after {
              background-color: $blue2;
              border: 2px solid $blue2;
            }

            &::before {
              color: $white;
            }
          }
        }

        &::after {
          background: $blue2;
        }
      }

      // Navigation
      &.cb-stepper-disabled {
        a {

          &:hover,
          &:focus {
            text-decoration: none;
            cursor: not-allowed;
          }
        }
      }

      &:last-child {
        &::after {
          width: 0;
          height: 0;
        }
      }
    }
  }

  &:not(.cb-stepper-simple) {
    li {
      &.cb-stepper-complete {
        .cb-glyph {
          &::before {
            content: "\e90b";
          }
        }
      }

    }

    &:not(.cb-stepper-horizontal-fix) {

      // mobile + tablet
      @include media-breakpoint-down('sm', $grid-breakpoints) {
        @include cb-v-stepper();
      }
    }
  }

  &.cb-stepper-vertical {
    @include cb-v-stepper();
  }

  // Simple
  &.cb-stepper-simple {

    ul,
    ol {
      li {
        p {
          display: none;
          visibility: hidden;
        }

        .cb-glyph {
          color: $blue2;

          &.cb-circle {
            color: $gray2;
          }
        }

        &.cb-stepper-skip,
        &.cb-stepper-active {
          .cb-glyph {

            &.cb-circle,
            &.cb-disc {
              color: $blue2;
            }
          }
        }

        &::after {
          width: calc(100% - 24px);
          top: 9px;
          left: calc(50% + 12px);
        }
      }
    }
  }

  // Content Glyph 
  &.cb-stepper-content-glyph {

    ul,
    ol {
      li {
        &::after {
          height: 2px;
          width: calc(100% - 80px);
          background: $gray2;
          content: '';
          display: block;
          position: absolute;
          top: 32px;
          left: calc(50% + 40px);
        }

        &:last-child {
          &::after {
            width: 0;
            height: 0;
          }
        }
      }
    }

    // COLOR
    @include glyph-multi-content-color-stepper (blue2, $white, $black1, $blue2);
    @include glyph-multi-content-color-stepper (blue3, $white, $black1, $blue3);
    @include glyph-multi-content-color-stepper (blue4, $white, $black1, $blue4);
    @include glyph-multi-content-color-stepper (yellow1, $black1, $black1, $yellow1);
    @include glyph-multi-content-color-stepper (purple1, $white, $black1, $purple1);
    @include glyph-multi-content-color-stepper (purple2, $white, $black1, $purple2);
    @include glyph-multi-content-color-stepper (green1, $white, $black1, $green1);
    @include glyph-multi-content-color-stepper (green2, $white, $black1, $green2);
  }
}