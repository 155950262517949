.cb-table-top-scroll-holder {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  .cb-table-top-scroll {
    height: 1px;
  }
}

.cb-table-wrapper.with-top-scroll {
  overflow-x: auto;
}