/*
 * CB Glyph Content Multi
 */

 .cb-glyph-content-multi {
  position: relative;
	display: inline-block;
	width: 1em;
	height: 1em;
  border-radius: 50%;
	font-size: 4rem;
	line-height: 1em;
  @include glyph-font(CB Icons Multi);
	&::before,
	&::after {
	  position: absolute;
	  top: 0;
	  left: 0;
	}

  @each $key, $map in $multiGlyph {
    &.cb-#{$key} {
      @include glyph-content(map-get($map, "before"), map-get($map, "after"));
    }
  }

  // ------------------------------------ SIZE

  @each $key, $value in $multiGlyphSize {
    &.cb-glyph-#{$key} {
      font-size: calc(1rem*#{$value});
    }
  }

  // ------------------------------------ COLOR
  
  // default
  @include glyph-multi-content-color ($white, $yellow1, $blue2);

  &.cb-glyph-black1 {
    @include glyph-multi-content-color  ($white, $white, $black1);
	}
	&.cb-glyph-white {
    @include glyph-multi-content-color  ($black1, $black1, $white);
	}

  &.cb-glyph-blue2 {
    @include glyph-multi-content-color  ($white, $yellow1, $blue2);
	}
	&.cb-glyph-blue3 {
	  @include glyph-multi-content-color  ($white, $black1, $blue3);
	}
	&.cb-glyph-blue4 {
	  @include glyph-multi-content-color  ($white, $black1, $blue4);
	}

	&.cb-glyph-yellow1 {
	  @include glyph-multi-content-color  ($black1, $black1, $yellow1);
	}


	&.cb-glyph-purple1 {
	  @include glyph-multi-content-color  ($white, $black1, $purple1);
	}
	&.cb-glyph-purple2 {
	  @include glyph-multi-content-color  ($white, $black1, $purple2);
	}
	
	&.cb-glyph-green1 {
	  @include glyph-multi-content-color  ($white, $black1, $green1);
	}
	&.cb-glyph-green2 {
	  @include glyph-multi-content-color  ($white, $black1, $green2);
	}
}