/*
 * CB VARIABLES
 */

// ------------------------------------ NAMESPACE

$apricotNamespace: 'cb-apricot';
// $apricotNamespace: null;

// ------------------------------------ PATHS

$fontPath: '../../fonts/';

// ------------------------------------ WHITE
$white: #ffffff;

// ------------------------------------ BLACK

$black1:#1e1e1e;
$black2:#151515;
$black:#000;

// ------------------------------------ BLUE


$blue1: #006298;
$blue2: #0077c8; //Link Color , blue accent1
$blue3: #009cde;
$blue4: #71c5e8;

$outlineColor: #7ab6fd;


$blue5: #324dc7; //New brand Color
$blue5-shade: #192764;
$blue5-shade2: #2d45b3;
$blue6: #e5f5fc; //selected table cell


// ------------------------------------ PURPLE

$purple1: #702f8a;
$purple2: #a05eb5;

$purple3: #85144b;


// ------------------------------------ GREEN

$green1: #3a913f;
$green2: #6cc24a;

// ------------------------------------ GRAY


// corporate
$gray1: #505050;
$gray2: #888888;
$gray3: #b2b2b2;
$gray4: #d9d9d9;
$gray5: #f0f0f0; //digital

// ------------------------------------ YELLOW


$yellow1: #fedb00;
$yellow2: #fdf4ba; //highlight table cell

$yellow3: #e5c500; //Active button
// ------------------------------------ ORANGE

$orange1: #e57200;

// ------------------------------------ RED

$red1: #c13145; //Text, Alerts

// ------------------------------------ PROGRAM

$k-12: $blue3;
$k-12-accent1: $blue4;
$k-12-accent2: $blue2;

$higher-education: $purple1;
$higher-education-accent1: $purple2;

$green1: $green1;
$green2: $green2;

// ------------------------------------ COLOR MAP

$colorTypes: () !default;
$colorTypes: ("bg": background-color,
  "color": color,
  "border": border-color);

$colorThemes: () !default;
$colorThemes: map-merge((white: (name: white,
      code: $white,
      color: 1,
      bg: 1,
      border: 1,
      type: base),
    black1: (name: black-corporate,
      code: $black1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    black2: (name: black2-corporate,
      code: $black2,
      color: 0,
      bg: 1,
      border: 0,
      type: corporate),
    blue1: (name: blue-corporate,
      code: $blue1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    blue2: (name: accent1-blue-corporate,
      code: $blue2,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    blue3:(name: accent2-blue-corporate,
      code: $blue3,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    blue4: (name: k-12-accent,
      code: $blue4,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate),
    blue5: (name: blue6-corporate,
      code: $blue5,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    gray1: (name: gray1-corporate,
      code: $gray1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    gray2: (name: gray2-corporate,
      code: $gray2,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate),
    gray3: (name: gray3-corporate,
      code: $gray3,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate),
    gray4: (name: gray4-corporate,
      code: $gray4,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    gray5: (name: digital-gray,
      code: $gray5,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate),
    yellow1: (name: yellow-corporate,
      code: $yellow1,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate),
    orange1: (name: orange-corporate,
      code: $orange1,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate),
    red1: (name: red1,
      code: $red1,
      color: 1,
      bg: 1,
      border: 1,
      type: base),
    purple1: (name: higher-education,
      code: $purple1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    purple2: (name: higher-education-accent,
      code: $purple2,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate),
    green1: (name: access,
      code: $green1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate),
    green2: (name: access-accent,
      code: $green2,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate)),
  $colorThemes);


// ------------------------------------ PALETTES , TINT and SHADES

$paletteColors: (
  blue2: $blue2,
  blue5: $blue5,
  purple1: $purple1,
  green1: $green1
);

$tintColors: (
  blue2: $blue2,
  purple1: $purple1,
  green1: $green1
);

// RGBA(code, 0.8)
// https://tdekoning.github.io/rgba-converter/
// https://www.rapidtables.com/convert/color/hex-to-rgb.html

$paletteSVGColors: (
  blue2: #1A84CD,
  blue5: #465FCC,
  purple1: #7E4495,
  green1: #4E9C52
);

// ------------------------------------ SPACER

$spacer-v: 24;
$spacer-h: 24;

// ------------------------------------ BORDER

$border-color: $gray4;
$border-width: 1px;

// ------------------------------------ BREAKPOINTS

// 7 breakpoints
$grid-breakpoints: (xs: 1px,
  sm: 768px,
  md: 1024px,
  lg: 1248px,
  xl: 1344px,
  2xl: 1440px,
  3xl: 1728px) !default;

$container-max-widths: (xs: 0,
  sm: 720px,
  md: 976px,
  lg: 1200px,
  xl: 1296px,
  2xl: 1392px,
  3xl: 1680px,
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;
$enable-grid-classes: true !default;
$body-bg: $white;


// ------------------------------------ PRINT

$grid-breakpoints-print: (xs: 1px,
  sm: 1px,
  md: 1px) !default;


// Printing
$print-page-size: a3!default;
$print-body-min-width: map-get($grid-breakpoints, "sm") !default;


// ------------------------------------ TEXT

$font-color-base: $black1;

$font-size-base: 16px;
$line-height-base: 1.5em;
$font-family-base: Roboto,
sans-serif;
$font-weight-base: 400;
$line-height-base: 1.5 !default;

$font-size-small: 0.875rem; //14
$line-height-small: 1.7142857142857142em; //24

$font-size-xsmall: 0.75rem; //12
$line-height-xsmall: 2em; //24

$font-size-xlarge: 6rem;
$line-height-xlarge: 1em;

$font-weight-bold: 700;
$font-weight-black: 900;

$font-family-roboto: "Roboto",
sans-serif;
$font-family-roboto-slab: "RobotoSlab",
sans-serif;

$font-family-h: $font-family-roboto;

// XS (mobile first) values
$heading: (h1: (size: 2rem, //32
    line: 1.25em //40
  ),
  h2: (size: 1.75rem, //28
    line: 1.1428571428571428em //32
  ),
  h3: (size: 1.3125rem, //21
    line: 1.1428571428571428em //24
  ),
  h4: (size: 1.1875rem, //19
    line: 1.263157894736842em //24
  ),
  h5: (size: 1rem, //16
    line: 1.5em //24
  ),
  h6: (size: 0.875rem, //14
    line: 1.1428571428571428em //16
  ));

$body: (1: (size: 1rem,
    line: 1.5em,
    weight: null),
  2: (size: 0.875rem,
    line: 1.7142857142857142em,
    weight: null),
  3: (size: 1.125rem,
    line: 1.3333333333333333em,
    weight: null),
  4: (size: 1.4375rem,
    line: 1.0434782608695652em,
    weight: 300
  ),
  5: (size: 1.4375rem,
    line: 1.0434782608695652em,
    weight: 700),
  6: (size: 1.5rem,
    line: 1.3333333333333333em,
    weight: 700));

$font-size-sm: $font-size-base * .875 !default;
$zindex-tooltip: 1070 !default;

$border-radius: 8px;
$border-radius-input: $border-radius;

$textAlign: (left,
  right,
  center);

$fontWeight: (thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900);

// ------------------------------------ LINK

$link-color: $blue5;
$link-active-color: $blue5-shade;
$link-hover-color: $blue5;
$link-visited-color: $purple3;

$link-color-black: $black1;
$link-active-color-black: $gray1;
$link-hover-color-black: $black1;

// ------------------------------------ LIST

$list-offset-left: 24px;


// ------------------------------------ BORDERS
$borderType: (top,
  right,
  bottom,
  left);


$borderStyle: (solid,
  dashed);

$radiusSize: (8,
  16);

$borderWidth: (1,
  2,
  4,
  8,
  16,
  24);
// ------------------------------------ SPACER, MARGIN, PADDING

$spacersH: (4: ($spacer-h / 6),
  8: ($spacer-h / 3),
  16: (16),
  24: $spacer-h,
  32: (32),
  72: ($spacer-h * 3));

$spacersV: (8: ($spacer-v / 3),
  16: (16),
  24: ($spacer-v),
  32: (32),
  48: ($spacer-v * 2),
  72: ($spacer-v * 3));

// MARGIN + PADDING
$mpValue: 4,
8,
16,
24,
32,
48,
72;
$mpList: "top",
"bottom",
"left",
"right";

// ------------------------------------ LOGOS
$svgLogos: "logo-acorn",
"logo",
"programs-access",
"programs-accuplacer",
"cb-programs-ap",
"programs-ap",
"programs-clep",
"programs-css-profile",
"programs-powerfaids",
"programs-pre-ap",
"programs-psat-8-9",
"programs-psat-10",
"programs-psat-nmsqt",
"programs-sat",
"programs-springboard",
"search",
"programs-college-planning";

// ------------------------------------ GLYPHS
$logoGlyphs: ("logo-acorn": e901,
  "logo": e902,
  "programs-access": e903,
  "programs-accuplacer": e904,
  "cb-programs-ap": e905,
  "programs-ap": e905,
  "programs-clep": e906,
  "programs-css-profile": e907,
  "programs-powerfaids": e908,
  "programs-pre-ap": e909,
  "programs-psat-8-9": e90a,
  "programs-psat-10": e90b,
  "programs-psat-nmsqt": e90c,
  "programs-sat": e90d,
  "programs-springboard": e90e,
  "search": e90f,
  "programs-college-planning": e900,
  "programs-big-future": e911,
  "higher-ed-search": e910);


// default: 1rem
$glyphSize: () !default;
$glyphSize: map-merge(("xs": 1,
    "sm": 2,
    "md": 3,
    "lg": 4,
    "xl": 5),
  $glyphSize);

$glyphs: () !default;
$glyphs: map-merge(("online-tool": e936,
    "download": e914,
    "batch-download": e902,
    "menu-local": e925,
    "filter": e91d,
    "sort": e947,
    "sort-desc": e913,
    "sort-asc": e950,
    "up": e950,
    "down": e913,
    "left": e921,
    "right": e940,
    "dbl-left": e910,
    "dbl-right": e911,
    "mail": e928,
    "fax": e91c,
    "home": e91f,
    "location": e926,
    "phone-alt": e938,
    "resources": e93f,
    "cal-full": e907,
    "east": e916,
    "cal-empty": e906,
    "note": e935,
    "lightbulb": e922,
    "question": e93c,
    "megaphone": e92a,
    "plus": e93a,
    "minus": e92c,
    "chat-bubble": e909,
    "facebook": e91b,
    "linkedin": e923,
    "twitter": e94f,
    "youtube": e956,
    "instagram": e920,
    "compose": e90d,
    "upload": e951,
    "list": e924,
    "user": e952,
    "settings": e944,
    "error": e918,
    "book": e905,
    "tag": e94c,
    "check": e90b,
    "menu": e92b,
    "exclamation": e919,
    "draft-in": e915,
    "new-window": e930,
    "no-draft": e931,
    "nav-links": e92d,
    "quote-right": e93e,
    "quote-left": e93d,
    "x-mark": e955,
    "circle": e90c,
    "disc": e912,
    "north-east": e932,
    "creditcard": e90f,
    "educator": e917,
    "series": e943,
    "bell": e904,
    "pending": e937,
    "signed-in": e946,
    "matrix": e929,
    "search": e942,
    "cart": e908,
    "globe": e91e,
    "acorn": e957,
    "batch-upload": e903,
    "needs-score": e92e,
    "test-scored": e94e,
    "save-date": e941,
    "north-west": e933,
    "west": e953,
    "south-west": e94a,
    "south-east": e949,
    "barchart": e901,
    "print": e93b,
    "chat": e90a,
    "window": e954,
    "bag": e900,
    "expand-window": e91a,
    "task-complete": e94d,
    "new-doc": e92f,
    "sound": e948,
    "computer": e90e,
    "share": e945,
    "lock": e927,
    "phone": e939,
    "disc-alt": e958,
    "box-fill": e959,
    "box": e95a,
    "play": e95b,
    "check-alt": e95c,
    "check-circle": e95d,
    "see-on": e95e,
    "see-off": e95f,
    "exclamation-circle": e960,
    "full-screen": e961,
    "exit-full-screen": e962,
    "slideshow-play": e963,
    "slideshow-pause": e964,
    "gallery-forward": e966,
    "gallery-backward": e965,
    "carousel-backward": e967,
    "carousel-forward": e968,
    "carousel-backward-sm": e969,
    "carousel-forward-sm": e96a
    ),
  $glyphs);


// default: 1rem
$multiGlyphSize: ("sm": 2,
  "lg": 4,
  "xl": 6);


$multiGlyph: ("accessible": ("before": e900,
    "after": e97c),
  "app": ("before": e901,
    "after": e97d),
  "bag": ("before": e902,
    "after": e97e),
  "barchart": ("before": e903,
    "after": e97f),
  "batch-download": ("before": e904,
    "after": e980),
  "batch-upload": ("before": e905,
    "after": e981),
  "bell": ("before": e906,
    "after": e982),
  "book": ("before": e907,
    "after": e983),
  "bullet-list": ("before": e908,
    "after": e984),
  "bullet-list-doc": ("before": e909,
    "after": e985),
  "calculator": ("before": e90a,
    "after": e986),
  "calendar-empty": ("before": e90b,
    "after": e987),
  "calendar-full": ("before": e90c,
    "after": e988),
  "cart": ("before": e90d,
    "after": e989),
  "chat": ("before": e90e,
    "after": e98a),
  "check": ("before": e910,
    "after": e98b),
  "check-list": ("before": e911,
    "after": e98c),
  "check-list-doc": ("before": e912,
    "after": e98d),
  "college-reputation": ("before": e914,
    "after": e98e),
  "compose": ("before": e915,
    "after": e98f),
  "computer": ("before": e916,
    "after": e990),
  "credit-card": ("before": e917,
    "after": e991),
  "dbl-left": ("before": e91a,
    "after": e994),
  "dbl-right": ("before": e91b,
    "after": e995),
  "dollar": ("before": e91d,
    "after": e997),
  "download": ("before": e91f,
    "after": e999),
  "educator": ("before": e920,
    "after": e99a),
  "error": ("before": e922,
    "after": null),
  "everyday-words": ("before": e924,
    "after": e99c),
  "expand-window": ("before": e926,
    "after": e99e),
  "fax": ("before": e929,
    "after": e9a0),
  "full-length-practice": ("before": e92b,
    "after": e9a1),
  "globe": ("before": e967,
    "after": e965),
  "graduate-pro-school": ("before": e92d,
    "after": e9a3),
  "graduation": ("before": e92e,
    "after": e9a4),
  "home": ("before": e92f,
    "after": e9a5),
  "information": ("before": e930,
    "after": e9a6),
  "instagram": ("before": e931,
    "after": null),
  "facebook": ("before": e928,
    "after": null),
  "youtube": ("before": e97b,
    "after": null),
  "linkedin": ("before": e934,
    "after": null),
  "twitter": ("before": e972,
    "after": null),
  "light-bulb": ("before": e933,
    "after": e9a8),
  "list": ("before": e935,
    "after": e9a9),
  "local-menu": ("before": e936,
    "after": e9aa),
  "location": ("before": e937,
    "after": e9ab),
  "lock": ("before": e938,
    "after": e9ac),
  "lock-open": ("before": e939,
    "after": e9ad),
  "mail": ("before": e93a,
    "after": e9ae),
  "matrix": ("before": e93b,
    "after": e9af),
  "megaphone": ("before": e93c,
    "after": e9b0),
  "menu": ("before": e93d,
    "after": e9b1),
  "minus": ("before": e93e,
    "after": e9b2),
  "nav-links": ("before": e93f,
    "after": e9b3),
  "new-doc": ("before": e941,
    "after": e9b5),
  "new-window": ("before": e942,
    "after": e9b6),
  "note": ("before": e947,
    "after": e9b9),
  "online-tool": ("before": e948,
    "after": e9ba),
  "pending": ("before": e949,
    "after": e9bb),
  "phone": ("before": e94a,
    "after": e9bc),
  "phone-alt": ("before": e94b,
    "after": e9bd),
  "plus": ("before": e94c,
    "after": e9be),
  "practice": ("before": e94d,
    "after": e9bf),
  "practice-test": ("before": e94e,
    "after": e9c0),
  "presentation": ("before": e94f,
    "after": e9c1),
  "print": ("before": e950,
    "after": e9c2),
  "problem-feedback": ("before": e951,
    "after": e9c3),
  "raided-pencil": ("before": e955,
    "after": e9c5),
  "resources": ("before": e956,
    "after": e9c6),
  "rupee": ("before": e958,
    "after": e9c8),
  "save-date": ("before": e959,
    "after": e9c9),
  "science": ("before": e95d,
    "after": e9cc),
  "search": ("before": e95e,
    "after": e9cd),
  "series": ("before": e95f,
    "after": e9ce),
  "settings": ("before": e960,
    "after": e9cf),
  "share": ("before": e961,
    "after": e9d0),
  "share-alt": ("before": e962,
    "after": e9d1),
  "sound": ("before": e966,
    "after": e9d4),
  "special-edition": ("before": e969,
    "after": e9d5),
  "tag": ("before": e96b,
    "after": e9d6),
  "task-complete": ("before": e96d,
    "after": e9d8),
  "teacher-edition": ("before": e96e,
    "after": e9d9),
  "team-leader": ("before": e96f,
    "after": e9da),
  "test-scored": ("before": e970,
    "after": e9db),
  "tuition-financial-aid": ("before": e971,
    "after": e9dc),
  "upload": ("before": e974,
    "after": e9de),
  "user": ("before": e975,
    "after": null),
  "video-lesson": ("before": e976,
    "after": e9df),
  "window": ("before": e978,
    "after": null),
  "work-together": ("before": e979,
    "after": e9e0),
  "quote-left": ("before": e913,
    "after": e91c),
  "quote-right": ("before": e91e,
    "after": e919),
  "add-user": ("before": e9e2,
    "after": e9ec),
  "dollars": ("before": e9e3,
    "after": e9ed),
  "essential-math": ("before": e9e4,
    "after": e9ee),
  "explore": ("before": e9e5,
    "after": e9ef),
  "face-happy": ("before": e9e6,
    "after": e9d3),
  "face-unhappy": ("before": e9e7,
    "after": e9d3),
  "face-unsure": ("before": e9e8,
    "after": e9d3),
  "maths": ("before": e9ea,
    "after": e9f3),
  "school": ("before": e9eb,
    "after": e9f4),
  "exam": ("before": e921,
    "after": e944),
  "khan-academy": ("before": e90f,
    "after": null),
  "send-scores": ("before": e925,
    "after": e945),
  "submit-documents": ("before": e92a,
    "after": e946),
  "submit-portfolio": ("before": e932,
    "after": e952),
  "play": ("before": e953,
    "after": null),
  "signed-in": ("before": e957,
      "after": e954),
);

// ------------------------------------ DISPLAYS

$displays: none,
inline,
inline-block,
block,
table,
table-row,
table-cell,
flex,
inline-flex !default;
$overflows: auto,
hidden !default;
$positions: static,
relative,
absolute,
fixed,
sticky !default;

// ------------------------------------ POSITIONS

$positions: relative,
absolute,
fixed,
static,
sticky;


// ------------------------------------ BUTTON

$btn-font-family: $font-family-roboto;
$btn-font-color: $blue5;
$btn-font-weight: 700;
$btn-font-size: 0.875rem;
$btn-line-height: 1.7142857142857142em; //24
$btn-line-height-safari: 1.6428571428571428em; //23

$btn-border-width: 1px;
$btn-border-color: $blue5;
$btn-border-radius: 999px;
$btn-border-radius-input: $border-radius;
$btn-padding-v: 12px;
$btn-padding-h: 24px;

$btn-font-family-sm: $font-family-roboto;
$btn-padding-sm-v: 8px;
$btn-padding-sm-h: 24px;
$btn-font-size-sm: $font-size-small; //14px;
$btn-line-height-sm: 1.1428571428571428em; //16px;
$btn-line-height-sm-safari: 1.0714285714285714em; //15px;

$btn-padding-lg-v: 12px;
$btn-padding-lg-h: 52px;
$btn-font-size-lg: 1.25rem; //20px;
$btn-line-height-lg: 1.2em; //24px;

$transition-time-s: 0.25s;
$transition-time: 0.3s;
$transition-time-l: 0.35s;

$btn-transition: all $transition-time-s ease-in-out;

$btn-disabled-opacity: 1;

$dotWidth: 6px;
$dotHeight: 6px;
$dotRadius: 50%;
$dotSpacing: 12px;

$dotBgColor: transparent;
$dotColor: $blue5;

$d-max: 50px;
$d-min: 12px;

$btn-blue-action: rgba(50, 77, 199, 0.1);
$btn-blue-action-focus: rgba(50, 77, 199, 0.4);

// ------------------------------------ FORMS


$gray-light-border-color: #e5e2e0;
$cb-disabled-font-color: #989795;

//Forms
$input-bk: $gray5;

$input-focus: $blue5;

$input-border: $gray4;
$input-border-hover: $gray4;

$input-border-focus: $blue5;
$input-hover-shadow: #e7e6e5;
$input-placeholder: #737373;
$input-bg-disabled: #eee;

$input-padding-v: 12px;
$input-padding-h: 16px;

// Form Validation
$success-color: #3c763d;
$error-color: $red1;
$warning-color: #f90;

$success-glyph: 'cb-check';
$error-glyph: 'cb-x-mark';
$warning-glyph: 'cb-exclamation';

// ------------------------------------ TABLES


$table-border-color: $gray4;
$table-border-width: 1px;
$table-cell-padding: 11px 8px 12px 8px;
$table-condensed-cell-padding: 3px 4px 4px 4px;

// ------------------------------------ TOOLTIP

$tooltip-font-size: $font-size-sm !default;
$tooltip-min-width: 180px !default;
$tooltip-max-width: 360px !default;
$tooltip-color: $white !default;
$tooltip-bg: $gray1 !default;
$tooltip-border-radius: $border-radius;
$tooltip-border-color: $white !default;
$tooltip-opacity: .9 !default;
$tooltip-spacer: 12px !default;

// ------------------------------------ MODALS

// Modal
$zindex-modal-background: 1040;
$modal-backdrop-bg: rgba(30, 30, 30, .9); //$black3;

$modal-bg: $white;
$modal-opacity: 0.9;
$modal-border-radius: $border-radius;
$modal-content-border-color: rgba(0, 0, 0, .1);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: $gray3;
$modal-spacing: 24px;


// Loader and progress bar
$cb-spinner-light-blue: #c1dcf5;
$cb-spinner-dark-blue: $blue2;

$cb-spinner-white-radial: rgba(255, 255, 255, 0.3);

$cb-spinner-light-gray: #f1f1f2;
$cb-spinner-dark-gray: $gray1;

$columnCount: (2,
  3, 4);