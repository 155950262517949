/*
 * CB Buttons
 */

@import "apricot-variable.scss";
@import "../mixins/button.scss";
@import "../mixins/link.scss";
@import "../mixins/breakpoint.scss";

.cb-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  font-size: $btn-font-size;
  line-height: $btn-line-height;

  color: $btn-font-color;
  text-decoration: none;

  user-select: none;
  background-color: transparent;
  border-width: $btn-border-width;
  border-style: solid;
  border-color: $btn-border-color;
  box-sizing: border-box;

  border-radius: $btn-border-radius;

  padding: $btn-padding-v - 1px $btn-padding-h - 1px $btn-padding-v - 1px $btn-padding-h - 1px;
  transition: $btn-transition;

  .cb-glyph {
    font-size: 1em !important;
    line-height: 1.7142857142857142em !important;
  }

  &:hover,
  &.cb-hover {
    background-color: $gray5;
    box-shadow: inset 0 0 0 1px $blue5;
    text-decoration: none;
  }

  &:focus,
  &.cb-focus {
    outline: none;
    background-color: transparent;
    text-decoration: underline;
    box-shadow: inset 0 0 0 2px $blue5;
  }

  // Disabled comes first so active can properly restyle
  @include button-disabled($gray5, $gray5, $gray3);

  &:not(.cb-btn-square):not(.cb-btn-circle) {
    .cb-glyph {
      margin-left: 8px;
    }

    &.cb-btn-icon-left {
      .cb-glyph {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
}

// ------------------------------------ SIZE
.cb-btn-sm:not(.cb-btn-tag),
.cb-btn-small:not(.cb-btn-tag) {
  @include button-size(
    $btn-padding-sm-v,
    $btn-padding-sm-h,
    $btn-font-size-sm,
    $btn-line-height-sm
  );
}

// ------------------------------------ SAFARI Issue
[class*="safari"] {
  .cb-btn:not(.cb-btn-circle):not(.cb-btn-tag):not(.cb-btn-square) {
    padding-top: $btn-padding-v + 1px;
    padding-bottom: $btn-padding-v - 2px;
    &.cb-btn-sm,
    &.cb-btn-small {
      padding-top: $btn-padding-sm-v + 1px;
      padding-bottom: $btn-padding-sm-v - 2px;
    }
  }
  .cb-btn {
    &.cb-btn-tag {
      padding-bottom: 5px;
      span {
        &:first-of-type {
          padding-top: 2px;
        }
      }

      &.cb-btn-sm,
      &.cb-btn-small {
        padding: 3px 8px 1px 8px;
        // padding: 8px 8px 5px 8px;
      }
    }
  }
}

// ------------------------------------ LIGHT
.cb-btn-light {
  @include light();
}

// ------------------------------------ BLACK
.cb-btn-black {
  color: $black1;
  border-color: $black1;
  @include black-link();

  &:hover,
  &.cb-hover {
    box-shadow: inset 0 0 0 1px $black1;
  }

  &:focus,
  &.cb-focus {
    box-shadow: inset 0 0 0 2px $black1;
  }

  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-btn-light {
    @include light();
  }
}

// ------------------------------------ PRIMARY
.cb-btn-primary {
  background-color: $blue5;
  color: $white;
  border-color: $blue5;

  &:hover,
  &.cb-hover {
    background-color: mix(black, $blue5, 50%); //$blue5-shade;
    border-color: mix(black, $blue5, 50%);
    box-shadow: none;
  }

  &:focus,
  &.cb-focus {
    border-color: $white;
    background-color: $blue5;
    box-shadow: 0 0 0 2px $blue5;
  }

  &:not(.cb-btn-pulse) {
    &:focus,
    &.cb-focus,
    &:hover,
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $white;
        }
      }
    }
  }

  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-btn-light {
    @include primary-light();
  }

  // Palette
  @each $key, $value in $paletteColors {
    &.cb-palette-#{$key} {
      background-color: $value;
      border-color: $value;
      &:hover,
      &.cb-hover {
        background-color: mix(black, $value, 50%); //$blue5-shade;
        border-color: mix(black, $value, 50%);
      }

      &:focus,
      &.cb-focus {
        border-color: $white;
        background-color: $value;
        box-shadow: 0 0 0 2px $value;
      }
    }
  }
}

// ------------------------------------ YELLOW
.cb-btn-yellow {
  color: $black1;
  border-color: $black1;
  background-color: $yellow1;

  &:hover,
  &.cb-hover {
    background-color: $yellow1;
    box-shadow: inset 0 0 0 1px $black1;
    text-decoration: none;
  }

  &:focus,
  &.cb-focus {
    background-color: $yellow1;
    border-color: $white;
    box-shadow: 0 0 0 2px $black1;
  }

  &:not(.cb-btn-pulse) {
    &:focus,
    &.cb-focus,
    &:hover,
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $black1;
        }
      }
    }
  }

  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-btn-light {
    @include yellow-light();
  }
}

// ------------------------------------ CARD
.cb-btn-card-primary {
  color: $black1;
  border-color: $black1;
  font-weight: 400;
  @include black-link();

  &.cb-btn-sm,
  &.cb-btn-small {
    padding: 7px 15px;
    font-size: $btn-font-size-sm;
    line-height: $btn-line-height-sm;
  }

  &:hover,
  &.cb-hover {
    box-shadow: inset 0 0 0 1px $black1;
  }

  &:focus,
  &.cb-focus {
    box-shadow: inset 0 0 0 2px $black1;
  }

  @include button-disabled($gray5, $gray5, $gray3);
}

.cb-btn-card-secondary {
  background-color: transparent;
  border-color: transparent;
  color: $black1;
  font-weight: 500;
  text-decoration: underline;

  border-radius: 0;
  padding: $btn-padding-v - 1px 0;

  &:hover,
  &.cb-hover {
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
    box-shadow: none;
    color: $blue5;
  }
  @each $key, $value in $paletteColors {
    &.cb-palette-#{$key} {
      &:hover,
      &.cb-hover {
        color: $value;
      }
    }
  }

  &:focus,
  &.cb-focus {
    position: relative;
    border-color: transparent !important;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;

    &::after {
      position: absolute;
      content: "";
      display: block;
      width: calc(100% + 42px);
      height: calc(100% + 2px);
      top: -1px;
      left: -20px;
      border-radius: $btn-border-radius;
      box-shadow: inset 0 0 0 2px $black1;
    }
  }

  &.cb-btn-sm,
  &.cb-btn-small {
    padding: 7px 0;
    font-size: $btn-font-size-sm;
    line-height: $btn-line-height-sm;
    &:focus,
    &.cb-focus {
      &::after {
        width: calc(100% + 24px);
        height: calc(100% + 2px);
        top: -1px;
        left: -12px;
      }
    }
  }

  @include button-disabled(transparent, transparent, $gray3);
}

// ------------------------------------ RESPONSIVE BUTTONS

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .cb-btn-light-#{$breakpoint} {
      @include light();

      &.cb-btn-primary {
        @include primary-light();
      }

      &.cb-btn-black {
        @include light();
      }

      &.cb-btn-yellow {
        @include yellow-light();
      }
    }

    .cb-btn-sm-#{$breakpoint},
    .cb-btn-small-#{$breakpoint} {
      @include button-size(
        $btn-padding-sm-v,
        $btn-padding-sm-h,
        $btn-font-size-sm,
        $btn-line-height-sm
      );
    }
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .cb-btn-light-#{$breakpoint}-up {
      @include light();

      &.cb-btn-primary {
        @include primary-light();
      }

      &.cb-btn-black {
        @include light();
      }

      &.cb-btn-yellow {
        @include yellow-light();
      }
    }

    .cb-btn-sm-#{$breakpoint}-up,
    .cb-btn-small-#{$breakpoint}-up {
      @include button-size(
        $btn-padding-sm-v,
        $btn-padding-sm-h,
        $btn-font-size-sm,
        $btn-line-height-sm
      );
    }
  }

  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    .cb-btn-light-#{$breakpoint}-down {
      @include light();

      &.cb-btn-primary {
        @include primary-light();
      }

      &.cb-btn-black {
        @include light();
      }

      &.cb-btn-yellow {
        @include yellow-light();
      }
    }

    .cb-btn-sm-#{$breakpoint}-down,
    .cb-btn-small-#{$breakpoint}-down {
      @include button-size(
        $btn-padding-sm-v,
        $btn-padding-sm-h,
        $btn-font-size-sm,
        $btn-line-height-sm
      );
    }
  }
}

// ------------------------------------ TAG
.cb-btn-tag {
  color: $gray1;
  font-weight: 500;
  border-radius: 8px;
  background-color: $gray5;
  border-color: $gray4;
  padding: 7px 11px;

  .cb-glyph {
    font-size: $font-size-xsmall;
    line-height: $line-height-xsmall;
  }

  &:hover,
  &.cb-hover {
    box-shadow: inset 0 0 0 1px $gray1;
    border-color: $gray1;
    text-decoration: none;

    span {
      text-decoration: none;
    }
  }

  &:focus,
  &.cb-focus {
    text-decoration: underline;
    box-shadow: 0 0 1px 2px $gray1;
    border-color: $gray1;
    background-color: $gray5;
  }

  &:not(.cb-btn-pulse) {
    &:focus,
    &.cb-focus,
    &:hover,
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $gray1;
        }
      }
    }
  }

  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-btn-sm,
  &.cb-btn-small {
    .cb-glyph {
      font-size: $font-size-xsmall !important;
      line-height: 1em !important;
    }
    padding: 2px 8px;
    font-size: $font-size-xsmall;
    line-height: 1.5em;
    border-radius: 4px;
    font-weight: 400;
  }
}

// ------------------------------------ SQUARE
.cb-btn-square {
  border-radius: $btn-border-radius-input;
  font-size: 1rem;
  line-height: 1.5em;
  width: 3em;
  height: 3rem;
  line-height: 1em;
  padding: 0;

  &.cb-btn-sm,
  &.cb-btn-small {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 0.875em;
    font-size: $font-size-small;
    padding: 0;
  }

  &:focus,
  &.cb-focus {
    text-decoration: none;
  }
}

// ------------------------------------ CLOSE
.cb-btn-square {
  &.cb-btn-close {
    @include close();
  }
}

// ------------------------------------ CIRCLE
.cb-btn-circle {
  font-size: 1rem;
  line-height: 1.5em;
  border-radius: 50%;
  width: 3em;
  height: 3rem;
  padding: 0;

  &.cb-btn-sm,
  &.cb-btn-small {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 0.875em;
    font-size: $font-size-small;
    padding: 0;
  }

  &:focus,
  &.cb-focus {
    text-decoration: none;
  }
}

// ------------------------------------ FLOATING
.cb-btn-floating {
  border-color: transparent;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  color: $black1;
  background-color: $white;

  &:hover,
  &.cb-hover {
    &:not(:disabled),
    &:not(.cb-disabled) {
      background-color: $gray5;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    }
  }

  &:focus,
  &.cb-focus {
    &:not(:disabled),
    &:not(.cb-disabled) {
      box-shadow: inset 0 0 1px 2px $gray1;
    }
  }
}

// ------------------------------------ GRAYSCALE

.cb-btn-greyscale {
  border-color: $gray4;
  color: $black1;
  background-color: $white;

  &:hover,
  &.cb-hover {
    &:not(:disabled) {
      &:not(.cb-disabled) {
        color: $black1;
        background-color: $gray5;
        box-shadow: none;
      }
    }
  }

  &:focus,
  &.cb-focus {
    &:not(:disabled) {
      &:not(.cb-disabled) {
        color: $black1;
        box-shadow: inset 0 0 1px 2px $gray1;
      }
    }
  }
  &.cb-white-color {
    &:focus,
    &.cb-focus {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          box-shadow: inset 0 0 1px 2px $white;
        }
      }
    }
  }
}

// ------------------------------------ NAKED
.cb-btn-naked {
  background-color: transparent;
  border-color: transparent;
  color: $blue5;

  &:hover,
  &.cb-hover {
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
    box-shadow: none;
  }

  &:focus,
  &.cb-focus {
    border-color: $blue5;
    background-color: transparent;
    box-shadow: inset 0 0 0 3px $blue5;
  }

  @include button-disabled(transparent, transparent, $gray3);

  &.cb-btn-light {
    color: $white;

    &:focus,
    &.cb-focus {
      border-color: $white;
      background-color: transparent;
      box-shadow: inset 0 0 0 3px $white;
    }
  }

  &.cb-btn-black {
    color: $black1;
    background-color: transparent;
    border-color: transparent;

    @include button-disabled(transparent, transparent, $gray3);

    &:hover,
    &.cb-hover {
      background-color: transparent;
      border-color: transparent;
      text-decoration: underline;
      box-shadow: none;
    }

    &:focus,
    &.cb-focus {
      border-color: $black1;
      background-color: transparent;
      box-shadow: inset 0 0 0 3px $black1;
    }
  }

  &.cb-no-padding {
    border-radius: 0;

    &:focus,
    &.cb-focus {
      border-color: transparent !important;
      background-color: transparent;
      box-shadow: none !important;
      outline-style: auto !important;
      outline-color: $blue5 !important;
    }

    &.cb-no-outline {
      &:focus,
      &.cb-focus {
        position: relative;
        border-color: transparent !important;
        background-color: transparent;
        box-shadow: none !important;
        outline: none !important;

        &::after {
          position: absolute;
          content: "";
          display: block;
          width: calc(100% + 24px);
          height: calc(100% + 12px);
          top: -6px;
          left: -12px;
          border-radius: $btn-border-radius;
          box-shadow: inset 0 0 0 3px $blue5;
        }
      }
    }
  }
}

a {
  &.cb-btn-square,
  &.cb-btn-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

// ------------------------------------ BLOCK BUTTON
.cb-btn-block {
  display: block;
  width: 100%;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.cb-btn-block {
    width: 100%;
  }
}

.cb-btn-effect1 {
  background-color: rgba(255, 255, 255, 0.25);
}

// ------------------------------------ BUTTON ROW
.cb-btn-row {
  // To prevent horizontal space
  font-size: 0;
  flex-flow: wrap;
  margin-left: -16px;
  margin-top: -16px;

  .cb-btn {
    flex-shrink: 0;
    margin-top: 16px;
    margin-left: 16px;
  }

  .cb-btn + .cb-btn {
    margin-left: 16px;
  }

  &.cb-btn-row-sm {
    margin-left: -8px;
    margin-top: -8px;

    .cb-btn {
      font-family: $btn-font-family-sm;
      margin-top: 8px;
      margin-left: 8px;
      @include button-size(
        $btn-padding-sm-v,
        $btn-padding-sm-h,
        $btn-font-size-sm,
        $btn-line-height-sm
      );

      &.cb-btn-square {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 0.875em;
        font-size: $font-size-small;
        padding: 0;

        &.cb-btn-close {
          width: 1.5rem;
          height: 1.5rem;
          line-height: 0.875em;
          font-size: $font-size-small;
          padding: 0;
        }
      }

      &.cb-btn-circle {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 0.875em;
        font-size: $font-size-small;
        padding: 0;
      }
    }

    .cb-btn + .cb-btn {
      margin-left: 8px;
    }
  }
}

// ------------------------------------ TAG SET
.cb-btn-row {
  font-size: 1rem;

  &.cb-tag-set {
    display: flex;
    align-items: center;

    > .cb-glyph {
      flex-shrink: 0;
      margin-top: 16px;
      margin-left: 16px;
    }

    .cb-glyph {
      font-size: 1em;
    }
  }
}

// ------------------------------------ FLASHING
.cb-btn-flashing {
  @include dot();
  animation: dotFlashing 1s 0.5s infinite linear alternate;
  position: relative;

  &::before,
  &::after {
    @include dot();
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    animation: dotFlashing 1s infinite alternate;
    left: -$dotSpacing;
  }

  &::after {
    animation: dotFlashing 1s 1s infinite alternate;
    left: $dotSpacing;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $dotColor;
  }

  100% {
    background-color: lighten($dotColor, 20%);
  }
}

// ------------------------------------ PULSE
$leftPos: -9999px;
$x1: -$leftPos - $dotSpacing;
$x2: -$leftPos;
$x3: -$leftPos + $dotSpacing;

.cb-btn-pulse {
  position: relative;
  color: transparent;

  .cb-glyph {
    display: none;
  }

  .cb-btn-pulse-dots {
    position: absolute;
    top: 20px;
    left: 46%;

    .cb-btn-dots {
      @include dot();
      animation: dotPulse 1.5s infinite linear;
      box-shadow: $x1 0 0 0 $dotColor, $x2 0 0 0 $dotColor, $x3 0 0 0 $dotColor;
      left: $leftPos;
      position: relative;
    }
  }

  &.cb-btn-sm,
  &.cb-btn-small {
    .cb-btn-pulse-dots {
      top: 16px;
    }
  }

  &.cb-btn-naked {
    .cb-btn-pulse-dots {
      .cb-btn-dots {
        animation: dotPulse 1.5s infinite linear;
        box-shadow: $x1 0 0 0 $blue5, $x2 0 0 0 $blue5, $x3 0 0 0 $blue5;
      }
    }
  }

  &.cb-btn-black,
  &.cb-btn-yellow {
    .cb-btn-pulse-dots {
      .cb-btn-dots {
        animation: dotPulseBlack 1.5s infinite linear;
        box-shadow: $x1 0 0 0 $black1, $x2 0 0 0 $black1, $x3 0 0 0 $black1;
      }
    }
  }

  &.cb-btn-primary {
    .cb-btn-pulse-dots {
      .cb-btn-dots {
        animation: dotPulseWhite 1.5s infinite linear;
        box-shadow: $x1 0 0 0 $white, $x2 0 0 0 $white, $x3 0 0 0 $white;
      }
    }
  }

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus {
    text-decoration: none;
    cursor: default;
    color: transparent !important;
  }
  &:hover,
  &.cb-hover {
    cursor: pointer;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: $x1 0 0 -5px $dotColor, $x2 0 0 0 $dotColor, $x3 0 0 2px $dotColor;
  }

  25% {
    box-shadow: $x1 0 0 0 $dotColor, $x2 0 0 2px $dotColor, $x3 0 0 0 $dotColor;
  }

  50% {
    box-shadow: $x1 0 0 2px $dotColor, $x2 0 0 0 $dotColor, $x3 0 0 -5px $dotColor;
  }

  75% {
    box-shadow: $x1 0 0 0 $dotColor, $x2 0 0 -5px $dotColor, $x3 0 0 0 $dotColor;
  }

  100% {
    box-shadow: $x1 0 0 -5px $dotColor, $x2 0 0 0 $dotColor, $x3 0 0 2px $dotColor;
  }
}

@keyframes dotPulseWhite {
  0% {
    box-shadow: $x1 0 0 -5px $white, $x2 0 0 0 $white, $x3 0 0 2px $white;
  }

  25% {
    box-shadow: $x1 0 0 0 $white, $x2 0 0 2px $white, $x3 0 0 0 $white;
  }

  50% {
    box-shadow: $x1 0 0 2px $white, $x2 0 0 0 $white, $x3 0 0 -5px $white;
  }

  75% {
    box-shadow: $x1 0 0 0 $white, $x2 0 0 -5px $white, $x3 0 0 0 $white;
  }

  100% {
    box-shadow: $x1 0 0 -5px $white, $x2 0 0 0 $white, $x3 0 0 2px $white;
  }
}

@keyframes dotPulseBlack {
  0% {
    box-shadow: $x1 0 0 -5px $black1, $x2 0 0 0 $black1, $x3 0 0 2px $black1;
  }

  25% {
    box-shadow: $x1 0 0 0 $black1, $x2 0 0 2px $black1, $x3 0 0 0 $black1;
  }

  50% {
    box-shadow: $x1 0 0 2px $black1, $x2 0 0 0 $black1, $x3 0 0 -5px $black1;
  }

  75% {
    box-shadow: $x1 0 0 0 $black1, $x2 0 0 -5px $black1, $x3 0 0 0 $black1;
  }

  100% {
    box-shadow: $x1 0 0 -5px $black1, $x2 0 0 0 $black1, $x3 0 0 2px $black1;
  }
}
