/*
 * CB Opacity & Tint
 */
@import "apricot-variable.scss";

// ------------------------------------ OPACITY
@for $i from 0 through 9 {
  .cb-opacity-#{$i} {
    opacity: 0.1 * $i;
  }
}

.cb-no-opacity {
  opacity: 1;
}

// ------------------------------------ TINT
.cb-tint-blue5-1 {
  background-color: #E6EDF8;
}
.cb-tint-blue5-2 {
  background-color: #F5F7FC;
}


@each $key, $value in $tintColors {
  .cb-tint-#{$key}-90 {
    background-color: mix(white, $value, 90%);
  }
}

@each $key, $value in $tintColors {
  .cb-tint-#{$key}-95 {
    background-color: mix(white, $value, 95%);
  }
}

// ------------------------------------ SHADE

.cb-shade-blue5-1 {
  background-color: #28369a;
}
.cb-shade-blue5-2 {
  background-color: #1b2264;
}

@each $key, $value in $tintColors {
  .cb-shade-#{$key}-20 {
    background-color: mix(black, $value, 20%);
  }
}

@each $key, $value in $tintColors {
  .cb-shade-#{$key}-50 {
    background-color: mix(black, $value, 50%);
  }
}
