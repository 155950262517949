/*
 * CB Typography
 */


dt {
  margin-left: $list-offset-left;
  @include family(Roboto-Bold)
}

dd {
  margin-left: $list-offset-left;
}

// Base
ul,
ol {
  padding-left: ($list-offset-left * 2);

  li {

    ul,
    ol {
      padding-left: $list-offset-left;
    }

    ul {
      list-style: disc;
    }
  }
}

@include namespace() {
  ul {
    li {
      list-style: disc;
    }

    @include list-style();
  }
}

ul {
  &.cb-text-list {
    li {
      list-style: disc;
    }

    @include list-style();
  }

  &.cb-text-list-checkbox {
    @include list-checkbox ();

    ul,
    ol {
      @include list-checkbox ();
      ul,
      ol {
        @include list-checkbox ();
      }
    }
  }

  &.cb-text-list-checkmark {
    @include list-checkmark ();

    ul,
    ol {
      @include list-checkmark ();
      ul,
      ol {
        @include list-checkmark ();
      }
    }
  }

  &.cb-text-list-dash {
    @include list-dash ();

    ul,
    ol {
      @include list-dash ();
      ul,
      ol {
        @include list-dash ();
      }
    }
  }

  &.cb-no-text-list {
    list-style: none !important;

    li {
      list-style: none !important;

      ol,
      ul {
        li {
          list-style: none !important;

          &::before {
            content: '' !important;
          }
        }
      }
    }
  }

  &.cb-text-list-no-indent {
    padding-left: 0 !important;

    ul,
    ol {
      padding-left: 0 !important;

      li {
        text-indent: 0 !important;
      }
    }

    li {
      margin-left: 18px !important;
    }

    &.cb-list-no-style,
    &.cb-no-text-list {
      li {
        margin-left: 0 !important;
      }
    }
  }

  &.cb-text-list-no-bullet {
    list-style-type: none;
  }

  &.cb-text-list-feature {
    @include cb-text-list-feature();

    &.cb-text-list-checkmark {
      @include cb-text-list-checkmark();
    }
  }
}

ol {
  &.cb-text-list {
    @include list-style();
  }

  &.cb-text-list-order {
    margin: 0;
    list-style-type: none;

    >li {
      counter-increment: step1-counter;
      margin-bottom: 16px;
      position: relative;

      &::before {
        position: absolute;
        left: -48px;
        top: -5px;
        content: counter(step1-counter);
        margin-right: 16px;
        display: inline-block;
        color: $white;
        width: 2em;
        height: 2em;
        text-align: center;
        font-weight: 700;
        line-height: 2em;
        box-sizing: border-box;
        background-color: $blue2;
        border-radius: 50%;
      }
    }

    @include list-style();

    ul,
    ol {
      margin-left: 24px;
      padding: 0;

      ul,
      ol {
        margin-left: 24px;
      }
    }
  }

  &.cb-text-list-no-indent {
    padding-left: 0 !important;

    ul,
    ol {
      padding-left: 0 !important;
    }

    li {
      margin-left: 18px !important;
    }
  }
}